<template>
  <div id="mian">
    <div class="tipBox" v-if="tipShow == true">
        <div class="tipTitle"><i class="el-icon-success"></i>{{ tipTitData }}</div>
        <div class="tipBtn" @click="secondXBtn">关闭</div>
      </div>
    <div class="secret" v-if="tipShow == false">
      <div class="box">
          <div class="item">
            <span class="label">手机号码：</span>
            <div class="titS">
              <span>{{ phone }}</span>
              <span class="getCode" @click="getCode">{{
                timeOut == 0 ? "获取验证码" : `倒计时${timeOut}s`
              }}</span>
            </div>
          </div>
          <div class="item">
            <span class="label">短信验证码：</span>
            <el-input
              v-model="validateCode"
              placeholder="请输入验证码"
              maxlength="6"
              style="width: 200px;"
            ></el-input>
          </div>
          <div class="itembtn">
            <el-button
              type="primary"
              class="searchBt"
              @click="secondary()"
              :disabled="buttonFlag"
              >确定</el-button
            >
          </div>
      </div>      
    </div>
  </div>
</template>
<script>
import { secondaryPut , getDisplayTime } from "@/api/management/index.js";
import { validatecode } from "@/api/login.js";
export default {
  data() {
    return {
      value: true,
      codeShow: null,
      timeOut: 0,

      validateCode:"",
      userInfo: "",
      phone: "",
      display: false,

      tipShow:false,
      displayType:'',

      tipTitData:"",

      buttonFlag:false,

    };
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.getDisplayTime();
  },
  methods: {

    // getDisplayTime
    getDisplayTime(){
      getDisplayTime().then((res) => {
        if (res.resultStatus) {
          this.phone = res.resultData.phone;
          this.displayType = res.resultData.displayType;
          if(res.resultData.displayType == true){
            this.tipShow = true;
            this.tipTitle();
          }
        }
      })
    },

    secondary(){
      let data = {
        validateCode:this.validateCode,
        displayType:true,
        type:'setDisplayTime', 
      }
      if (!data.validateCode) {
        this.$message.error("验证码不能为空！");
        return;
      }
      this.buttonFlag = true;
      secondaryPut(data).then((res) => {
        setTimeout(() => {
          this.buttonFlag = false; 
        }, 2000);
        if (res.resultStatus) {
          this.tipShow = true;
          this.validateCode = "";
          this.tipTitle();
        }else{
          this.tipShow = false;
        }
      })
    },
    
    secondXBtn(){
      this.$confirm('此操作将修改状态, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.timeOut = 0;
          let data = {
            displayType:false,
          }
          secondaryPut(data).then((res) => {
            if (res.resultStatus) {
              this.tipShow = false;
              this.displayType = false;
            }else{
              this.tipShow = false;
            }
          })          
        }).catch(() => {         
          this.tipShow = true;
          this.displayType = true;
          this.timeOut = 0;
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
    },

    //设置提示
    tipTitle(){
      let myDate = new Date();
      let month = myDate.getMonth() + 1
      this.tipTitData = "当前账号已完成用户隐私二次验证，有效期至"+month+"月"+myDate.getDate()+"日"+"24时。"
    },

    // 获取验证码
    getCode() {
      if (this.timeOut != 0) {
          return;
        }
        let data = {
          username: this.userInfo.username,
          type:'setDisplayTime', 
        };
        this.timeOut = 60;
        validatecode(data).then((res) => {
          if (res.resultStatus) {
            this.codeShow = setInterval(() => {
              if (this.timeOut == 0) {
                clearInterval(this.codeShow);
              } else {
                this.timeOut--;
              }
            }, 1000);
          } else {
            clearInterval(this.codeShow);
            this.timeOut = 0;
          }
      });
    },


  },
};
</script>

<style scoped>

.tipBox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
    border-radius: 5px;
    background: rgba(72,184,182,0.1);
    padding: 0px 20px;
    color: #48b8b6;
    font-size: 14px;
}
.tipBox .tipTitle{
    font-size: 14px;
    box-sizing: border-box;
}
.tipBox .tipTitle i{
  margin-right: 5px;
}
.tipBox .tipBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 138, 255, 1);
    cursor: pointer;}

.secret{
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  margin-top: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  padding-top: 39px;
  padding-bottom: 39px;
}
.secret .box {
  width:300px;
  margin: auto;
}
.secret .box .item{
  display: flex;
  align-content: center;
  line-height: 40px;
  height: 40px;
  margin-bottom: 10px;
}
.secret .box .item .label{
  width: 84px;
  text-align: right;
  margin-right: 16px;
}
.secret .box .item .titS{
  width: calc(100% - 84px - 16px);
}
.secret .box .item .titS .getCode {
  display: inline-block;
  width: 70px;
  margin-left: 45px;
  color: #48b8b6;
  cursor: pointer;
}
.secret .box .itembtn{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:50px;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
} 
</style>
